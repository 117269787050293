import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildIndex } from '@adalo/utils'
import Tooltip from 'components/Shared/Tooltip'
import { singularize } from 'utils/strings'

import { getContextualTables } from 'utils/links'
import { NAVIGATION_BACK } from '@adalo/constants'

import {
  getInboundLinks,
  getInboundLinkNames,
  getApp,
  getComponent,
} from 'ducks/apps'
import { getTable } from 'ducks/apps/datasources'

import EmptyState from 'components/Shared/EmptyState'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import store from 'redux-store'

import Item from './Item'

import './ImplicitParams.scss'

class ImplicitParams extends Component {
  getPartialTables = () => {
    const { contextualTables, partialTables } = this.props
    const contextualTablesMap = buildIndex(contextualTables, itm => itm.tableId)

    return partialTables.filter(itm => !contextualTablesMap[itm && itm.tableId])
  }

  getMissingComponentNames = components => {
    const { inboundLinkNames } = this.props

    const result = []

    const missingComponents = Object.keys(inboundLinkNames).filter(
      id => !components.includes(id)
    )

    for (const id of missingComponents) {
      result.push(inboundLinkNames[id] || 'Deleted Screen')
    }

    return result
  }

  renderContextualTables = () => {
    const { contextualTables, displayFromSource, appId, componentId } =
      this.props

    const state = store.getState()
    const component = getComponent(state, appId, componentId)
    const name = component ? component.name : null

    if (contextualTables.length === 0) return null

    let collections = contextualTables.map(collectionName => {
      return (collectionName?.name && singularize(collectionName.name)) || null
    })

    collections = collections.filter(collectionItem => collectionItem != null)
    if (collections.length === 0) return null

    const tooltip =
      `This makes Current ${collections?.join(
        ', Current '
      )} Data available on the ${name} Screen.\n\n Check Out Our Help ` +
      `Doc on `

    return (
      <>
        <div className="implicit-params-description">
          <h5>
            {displayFromSource
              ? `Send This Data to  ${name} Screen`
              : `Linked Data`}
          </h5>
          {displayFromSource && (
            <>
              <div className="implicit-item-icon tooltip">
                <Tooltip
                  tooltip={
                    <>
                      {' '}
                      {tooltip}{' '}
                      <a
                        href="https://www.youtube.com/watch?v=dWJWNXn3RPc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        How to Link Data
                      </a>{' '}
                    </>
                  }
                  placement="bottom-start"
                  hideArrow
                >
                  <Icon type="help-small" />
                </Tooltip>
              </div>
            </>
          )}
        </div>
        {contextualTables.map(t => (
          <Item
            key={t.id}
            tableName={t.name}
            displayFromSource={displayFromSource}
          />
        ))}
      </>
    )
  }

  mountPartialTablesItems(
    partialTables,
    displayFromSource,
    messageSubtitle,
    shouldMountItem
  ) {
    const items = partialTables.map(t =>
      !displayFromSource || shouldMountItem.includes(t.id) ? (
        <Item
          partial
          key={t.id}
          tableName={t.name}
          missingComponentNames={this.getMissingComponentNames(t.components)}
          missingMessage={messageSubtitle}
          displayFromSource={displayFromSource}
        />
      ) : null
    )

    return items
  }

  renderPartialTables = () => {
    const { displayFromSource, appId, componentId } = this.props
    const state = store.getState()
    const component = getComponent(state, appId, componentId)
    const name = component ? component.name : null

    const messageHeader =
      (displayFromSource && 'Data Missing from this Link') || 'Missing Data'

    const messageSubtitle =
      (displayFromSource && `Now Unavailable on ${name} Screen`) ||
      'Missing from '

    const partialTables = this.getPartialTables()

    const shouldMountItem = []

    const mountMissingData = !displayFromSource
      ? true
      : partialTables
          .map(t => {
            if (this.getMissingComponentNames(t.components)?.length === 1) {
              shouldMountItem.push(t.id)

              return true
            }
          })
          .reduce((result, element) => {
            if (element === true) {
              result = element
            }

            return result
          }, false)

    if (partialTables.length === 0 || !mountMissingData) return null

    const partialTablesItems = this.mountPartialTablesItems(
      partialTables,
      displayFromSource,
      messageSubtitle,
      shouldMountItem
    )

    const collections = partialTables.map(collectionName => {
      return singularize(collectionName.name)
    })

    const tooltip =
      `This link removes Current ${collections?.join(', Current ')} ` +
      `Data from the ${name} Screen. If you aren't using that data ` +
      `there, then don't worry. If you are then remove this link.` +
      `\n\n Check Out Our Help ` +
      `Doc on `

    return (
      <>
        {displayFromSource ? (
          <>
            <div className="implicit-params-missing-link">
              <h5>{`${messageHeader}`}</h5>
              <div className="implicit-item-icon tooltip">
                <Tooltip
                  tooltip={
                    <>
                      {' '}
                      {tooltip}{' '}
                      <a
                        href="https://www.youtube.com/watch?v=dWJWNXn3RPc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        How to Link Data
                      </a>{' '}
                    </>
                  }
                  placement="bottom-start"
                  hideArrow
                >
                  <Icon type="help-small" />
                </Tooltip>
              </div>
            </div>
          </>
        ) : null}
        {partialTablesItems}
      </>
    )
  }

  renderTables = () => {
    const { contextualTables, displayFromSource, componentId } = this.props
    const linkBack = componentId === NAVIGATION_BACK

    if (linkBack) return null

    const partialTables = this.getPartialTables()

    if (contextualTables.length === 0 && partialTables.length === 0) {
      if (displayFromSource) {
        return null
      } else {
        return (
          <EmptyState>
            <h5>No Linked Data</h5>
            <p>Link a Screen to Add Data</p>
            <Button
              to="https://www.youtube.com/watch?v=dWJWNXn3RPc"
              target="_blank"
              orange
              text
              small
            >
              Learn How
            </Button>
          </EmptyState>
        )
      }
    }

    return (
      <>
        {contextualTables.length !== 0 ? null : (
          <> {displayFromSource ? null : <h5>Linked Data</h5>}</>
        )}

        {this.renderContextualTables()}
        {this.renderPartialTables()}
      </>
    )
  }

  render() {
    return <div className="implicit-params">{this.renderTables()}</div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    appId,
    componentId,
    screenId,
    originComponent,
    omitContextualTables,
  } = props

  const app = getApp(state, appId)
  const inboundLinkNames = getInboundLinkNames(state, appId, componentId)
  let contextualTables = []
  let partialTables = []

  if (!omitContextualTables) {
    contextualTables = (
      getContextualTables(
        getApp(state, appId),
        componentId,
        getInboundLinks(state, appId),
        undefined,
        undefined,
        false,
        screenId,
        originComponent
      ) || []
    ).map(opts => {
      return {
        ...opts,
        ...getTable(state, appId, opts.datasourceId, opts.tableId),
        id: opts.tableId,
      }
    })

    partialTables = (
      getContextualTables(
        getApp(state, appId),
        componentId,
        getInboundLinks(state, appId),
        undefined,
        undefined,
        true
      ) || []
    ).map(opts => {
      return {
        ...opts,
        ...getTable(state, appId, opts.datasourceId, opts.tableId),
        id: opts.tableId,
      }
    })
  }

  return { app, inboundLinkNames, contextualTables, partialTables }
}

export default connect(mapStateToProps)(ImplicitParams)
