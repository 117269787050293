import React from 'react'
import PropTypes from 'prop-types'
import {
  dataTypes,
  backgroundStyles,
  borderStyles,
  LABEL,
  SECTION,
  FORM,
} from '@adalo/constants'

import { connect } from 'react-redux'
import { defaults } from 'utils/objects'
import { scaleValue } from 'utils/zoom'
import { calculateLayout, FormButtonKeys } from 'utils/forms'
import { normalizeColor } from 'utils/colors'
import { getCurrentAppId } from 'ducks/editor/objects'
import { getAppBranding } from 'ducks/apps'
import CanvasObject from '../CanvasObject'
import BaseObject from '../BaseObject'
import FormField from './FormField'

const secondaryButtonDefaults = defaults[FORM].secondaryButton

class Form extends BaseObject {
  static defaultProps = {
    fields: [
      { label: 'Field 1', type: dataTypes.TEXT },
      { label: 'Field 2', type: dataTypes.TEXT },
      { label: 'Field 3', type: dataTypes.TEXT },
    ],
    submitButton: {
      text: 'SAVE',
      color: '@contrast:backgroundColor',
      backgroundColor: '@secondary',
    },
    secondaryButton: {
      enabled: false,
      ...secondaryButtonDefaults,
    },
  }

  static childContextTypes = {
    editable: PropTypes.bool,
    branding: PropTypes.object,
  }

  getChildContext = () => {
    const { branding } = this.props

    return {
      editable: false,
      branding,
    }
  }

  getOffset = index => {
    const { zoom } = this.props

    return scaleValue(index * 90, zoom)
  }

  getChildZoom = () => {
    const { zoom } = this.props

    return { ...zoom, offset: [0, 0] }
  }

  renderSubmitButton(layout) {
    const { submitButton, width, editorResizingProps, branding } = this.props
    const childZoom = this.getChildZoom()

    const { shadow, fontWeight } = submitButton
    let {
      borderColor,
      borderWidth,
      borderRadius = 4,
      padding = 10,
      fontSize,
      fontFamily = '@body',
    } = submitButton

    const text = submitButton.text || 'SAVE'
    const color = normalizeColor(submitButton.color || '#fff', branding)

    const backgroundColor = normalizeColor(
      submitButton.backgroundColor || '@primary',
      branding,
      submitButton
    )

    const wrapperX = editorResizingProps ? '50%' : 0
    const textX = editorResizingProps ? -width / 2 : 0
    const resizingProps = editorResizingProps
      ? { ...editorResizingProps, relativeX: 0 }
      : null

    fontSize = fontSize || 14
    borderColor = borderColor || '#999'
    borderWidth = borderWidth || 0
    const height = 2 * padding + 1.4 * fontSize

    const rectangleObject = {
      id: 'submit',
      type: SECTION,
      x: 0,
      y: layout.button,
      width,
      height,
      backgroundStyle: backgroundStyles.COLOR,
      backgroundColor,
      borderRadius,
      borderStyle: borderStyles.SOLID,
      borderColor,
      borderWidth,
      shadow,
      editorResizingProps: resizingProps,
    }

    const textObject = {
      text,
      color,
      fontSize,
      fontWeight,
      fontFamily,
      width,
      id: 'submit-text',
      layoutText: [text],
      textAlignment: 'center',
      type: LABEL,
      x: textX,
      y: layout.button + padding + 0.05 * fontSize,
      height: fontSize * 1.4,
    }

    return (
      <g>
        <CanvasObject
          zoom={childZoom}
          branding={branding}
          object={rectangleObject}
        />
        <svg x={wrapperX} y="0" height="100%" width="100%" overflow="visible">
          <CanvasObject
            zoom={childZoom}
            branding={branding}
            object={textObject}
          />
        </svg>
      </g>
    )
  }

  renderSecondaryButton(layout) {
    const { secondaryButton, width, editorResizingProps, branding } = this.props

    if (!secondaryButton || !secondaryButton.enabled) {
      return null
    }

    const childZoom = this.getChildZoom()

    const {
      shadow,
      fontWeight = secondaryButtonDefaults.fontWeight,
      borderColor = secondaryButtonDefaults.borderColor,
      borderWidth = secondaryButtonDefaults.borderWidth,
      borderRadius = 4,
      padding = 10,
      fontSize = 14,
      fontFamily = secondaryButtonDefaults.fontFamily,
    } = secondaryButton

    const text = secondaryButton.text ?? secondaryButtonDefaults.text
    const color = normalizeColor(
      secondaryButton.color || secondaryButtonDefaults.color,
      branding
    )

    const backgroundColor = normalizeColor(
      secondaryButton.backgroundColor ??
        secondaryButtonDefaults.backgroundColor,
      branding,
      secondaryButton
    )

    const wrapperX = editorResizingProps ? '50%' : 0
    const textX = editorResizingProps ? -width / 2 : 0
    const resizingProps = editorResizingProps
      ? { ...editorResizingProps, relativeX: 0 }
      : null

    const height = 2 * padding + 1.4 * fontSize

    const rectangleObject = {
      id: 'secondary',
      type: SECTION,
      x: 0,
      y: layout.button,
      width,
      height,
      backgroundStyle: backgroundStyles.COLOR,
      backgroundColor,
      borderRadius,
      borderStyle: borderStyles.SOLID,
      borderColor,
      borderWidth,
      shadow,
      editorResizingProps: resizingProps,
    }

    const textObject = {
      text,
      color,
      fontSize,
      fontWeight,
      fontFamily,
      width,
      id: 'secondary-text',
      layoutText: [text],
      textAlignment: 'center',
      type: LABEL,
      x: textX,
      y: layout.button + padding + 0.05 * fontSize,
      height: fontSize * 1.4,
    }

    return (
      <g>
        <CanvasObject
          zoom={childZoom}
          branding={branding}
          object={rectangleObject}
        />
        <svg x={wrapperX} y="0" height="100%" width="100%" overflow="visible">
          <CanvasObject
            zoom={childZoom}
            branding={branding}
            object={textObject}
          />
        </svg>
      </g>
    )
  }

  render() {
    const {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      zoom,
      fields,
      submitButton,
      width,
      branding,
      ...obj
    } = this.props

    const childZoom = this.getChildZoom()

    const layout = calculateLayout(this.props)

    const forgotPassword = submitButton.backgroundColor ?? '@secondary'

    const submitLayoutElement = layout.elements.find(
      e => e.key === FormButtonKeys.SUBMIT
    )
    const secondaryButtonLayoutElement = layout.elements.find(
      e => e.key === FormButtonKeys.SECONDARY
    )

    return (
      <g transform={`translate(${xScaled} ${yScaled})`}>
        <g pointerEvents="none">
          {fields.map((field, i) => (
            <FormField
              {...field}
              key={i} // eslint-disable-line
              index={i}
              zoom={childZoom}
              width={width}
              layout={layout.elements[i]}
              object={obj}
              branding={branding}
              forgotPassword={forgotPassword}
            />
          ))}
          {this.renderSubmitButton(submitLayoutElement)}
          {this.renderSecondaryButton(secondaryButtonLayoutElement)}
        </g>
        <rect
          stroke="none"
          fill="none"
          x={0}
          y={0}
          width={widthScaled}
          height={heightScaled}
          onMouseDown={this.handleMouseDown}
          onDoubleClick={this.handleDoubleClick}
        />
      </g>
    )
  }
}

const mapStateToProps = state => {
  return {
    branding: getAppBranding(state, getCurrentAppId(state)),
  }
}

export default connect(mapStateToProps)(Form)
