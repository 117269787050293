import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  dataTypes,
  backgroundStyles,
  borderStyles,
  borderPosition,
  LABEL,
  INPUT,
  SECTION,
  IMAGE_UPLOAD,
  DATE_PICKER,
  FILE_UPLOAD,
  SELECT,
} from '@adalo/constants'
import { getFormFieldStyle } from 'utils/forms'
import { normalizeColor } from 'utils/colors'
import CanvasObject from '../CanvasObject'

class FormField extends Component {
  static contextTypes = {
    branding: PropTypes.object,
  }

  getStyles = () => {
    return {}
  }

  getInputProps = () => {
    let {
      width,
      index,
      multiline,
      zoom,
      placeholder,
      layout,
      object,
      type,
      icon,
    } = this.props

    const { branding } = this.context
    const { editorResizingProps } = object || {}

    const fieldType = type
    let layerType = INPUT
    let y = layout.input
    const inputFontSize = getFormFieldStyle(object, 16, 'inputs', 'fontSize')
    const inputPadding = getFormFieldStyle(object, 10, 'inputs', 'padding')

    const inputStyles = {
      color: getFormFieldStyle(object, '@text', 'inputs', 'color'),
      accentColor: getFormFieldStyle(
        object,
        '@primary',
        'inputs',
        'accentColor'
      ),
      backgroundColor: getFormFieldStyle(
        object,
        '#fff',
        'inputs',
        'backgroundColor'
      ),
      borderColor: getFormFieldStyle(object, '#ccc', 'inputs', 'borderColor'),
      borderRadius: getFormFieldStyle(object, 4, 'inputs', 'borderRadius'),
      borderStyle: getFormFieldStyle(
        object,
        borderStyles.SOLID,
        'inputs',
        'borderStyle'
      ),
      borderWidth: getFormFieldStyle(object, 1, 'inputs', 'borderWidth'),
      fontWeight: getFormFieldStyle(object, '400', 'inputs', 'fontWeight'),
      fontFamily: getFormFieldStyle(object, '@body', 'inputs', 'fontFamily'),
      placeholderColor: getFormFieldStyle(
        object,
        '#aaa',
        'inputs',
        'placeholderColor'
      ),
      shadow: getFormFieldStyle(object, { enabled: false }, 'inputs', 'shadow'),
    }

    const otherProps = {}

    let height =
      fieldType === dataTypes.TEXT && multiline
        ? 1.3 * 2 * inputFontSize + 2 * inputPadding
        : 1.3 * inputFontSize + 2 * inputPadding

    if (fieldType === dataTypes.BOOLEAN) {
      width = 16
      height = 16
      layerType = SECTION
      y += 1
    }

    if (fieldType === dataTypes.DATE) {
      layerType = DATE_PICKER
    } else if (fieldType === dataTypes.DATE_ONLY) {
      const { datePickerStyle } = this.props

      layerType = DATE_PICKER
      otherProps.datePickerStyle = datePickerStyle || dataTypes.DATE_ONLY
    } else if (fieldType === dataTypes.IMAGE) {
      layerType = IMAGE_UPLOAD
      otherProps.imageUploadType = 'fullWidth'

      otherProps.color = normalizeColor(
        getFormFieldStyle(object, '@primary', 'inputs', 'accentColor'),
        branding
      )

      otherProps.backgroundColor = inputStyles.backgroundColor
      otherProps.borderRadius = inputStyles.borderRadius
      otherProps.borderColor = inputStyles.borderColor
      otherProps.borderWidth = inputStyles.borderWidth

      height = 212
    } else if (fieldType === dataTypes.FILE) {
      layerType = FILE_UPLOAD

      otherProps.color = normalizeColor(
        getFormFieldStyle(object, '@primary', 'inputs', 'accentColor'),
        branding
      )

      height = 80
    } else if (fieldType.type === 'belongsTo') {
      layerType = SELECT
    } else if (fieldType === dataTypes.LOCATION) {
      otherProps.icon = icon
    }

    if (editorResizingProps) {
      otherProps.editorResizingProps = { ...editorResizingProps, relativeX: 0 }
    }

    return {
      multiline,
      placeholder,
      height,
      x: 0,
      y,
      backgroundStyle: backgroundStyles.COLOR,
      borderPosition: borderPosition.INSIDE,
      fontSize: inputFontSize,
      padding: inputPadding,
      ...inputStyles,
      ...otherProps,
      id: `${object.id}-${index}-1`,
      type: layerType,
      zoom,
      width,
    }
  }

  getLabelProps = () => {
    let { label, width, index, zoom, layout, object, type } = this.props
    let x = 0

    if (type === dataTypes.BOOLEAN) {
      x = 24
      width -= 24
    }

    return {
      id: `${index}-0`,
      zoom,
      width,
      height: 20,
      x,
      y: layout.label,
      color: '@text',
      text: label,
      layoutText: [label],
      fontSize: 14,
      fontWeight: '400',
      fontFamily: getFormFieldStyle(object, '@body', 'labels', 'fontFamily'),
      type: LABEL,
      ...getFormFieldStyle(object, null, 'labels'),
    }
  }

  render() {
    const { label, zoom, branding } = this.props

    return (
      <g>
        {label ? (
          <CanvasObject
            zoom={zoom}
            branding={branding}
            object={this.getLabelProps()}
          />
        ) : null}
        <CanvasObject
          zoom={zoom}
          branding={branding}
          object={this.getInputProps()}
        />
      </g>
    )
  }
}

export default FormField
