import { getActionOptions } from 'utils/actions'
import { BEFORE, START_TRIAL, UPGRADE } from '../../../constants'
import { removeTemplate } from './Form/template'

const getMenu = ({
  datasources,
  datasourceType,
  screens,
  componentId,
  contextOptions,
  customActions,
  paying,
  isAfterTrial,
  appId,
  app,
  googleApiKeyIsValid,
  trialState,
  areCustomActionsEnabled,
  isGeolocationEnabled,
  handleTrial,
  hasFeatureTemplatesAction,
  filterMenuOptions,
}) => {
  const hoverContent = trialState === BEFORE && !paying ? START_TRIAL : UPGRADE

  const customActionParams = {
    disableCustomActions: !areCustomActionsEnabled,
    startTrial: trialState === BEFORE && !paying,
    onClick: () => handleTrial(trialState, paying, { noCreate: true }),
  }

  const geolocationParams = {
    googleApiKeyIsValid,
    geolocationDisabled: !isGeolocationEnabled,
    onClick: () => handleTrial(trialState, paying, {}, 'geolocation'),
    hoverContent,
  }

  const menuOptions = getActionOptions({
    datasourceType,
    datasources,
    screens,
    componentId,
    contextOpts: contextOptions,
    customActions: removeTemplate(customActions),
    paying,
    isAfterTrial,
    appId,
    app,
    customActionParams,
    geolocationParams,
    hasFeatureTemplatesAction,
  })

  if (filterMenuOptions) {
    return filterMenuOptions(menuOptions)
  }

  return menuOptions
}

export default getMenu
