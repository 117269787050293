import React, { Component } from 'react'
import { dataTypes, sourceTypes, bindingTypes } from '@adalo/constants'
import { GroupedAccordion } from 'components/Shared/Accordion'
import { IconButton } from 'components/Shared/Icon'
import ToggleField from 'components/Shared/Forms/ToggleField'
import { buildBinding } from 'utils/bindings'
import IconRow from '../Libraries/IconRow'
import MenuControl from '../Libraries/MenuControl'
import SimpleTextControl from '../Libraries/SimpleTextControl'
import ListFilters from '../Libraries/ListFilters'
import SelectSort from './SelectSort'

class FieldItem extends Component {
  handleChange = field => {
    const { fieldIndex, onChange } = this.props

    onChange(fieldIndex, field)
  }

  handleDelete = () => {
    const { fieldIndex, onDelete } = this.props

    onDelete(fieldIndex)
  }

  handleChangeSort = sort => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...sort,
            reference: field.binding.source.sort?.reference,
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeSortLocationSource = locationSource => {
    const { field } = this.props
    const { source } = locationSource || {}
    let binding = null

    if (!field?.binding?.source) return

    if (
      source?.type === sourceTypes.FIELD ||
      source?.type === sourceTypes.INPUT
    ) {
      binding = buildBinding(bindingTypes.SET_LOCATION, source)
    }

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...field.binding.source.sort,
            reference: {
              fallback: field.binding.source.sort?.reference?.fallback,
              source: { ...source, binding },
            },
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeSortLocationFallback = fallback => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...field.binding.source.sort,
            reference: {
              fallback,
              source: field.binding.source.sort?.reference?.source,
            },
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeFilter = filter => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          options: {
            ...field.binding.source.options,
            filter,
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleRequiredToggle = required => {
    return this.handleChange({ required })
  }

  handleRequiredTextChange = e => {
    const { value: requiredText } = e.target

    return this.handleChange({ requiredText })
  }

  renderChildren = () => {
    const { field, appId, object, componentId } = this.props
    let sortValue = null
    let locationSource = null
    let locationFallback = null

    const showPlaceholder =
      [
        dataTypes.TEXT,
        dataTypes.NUMBER,
        dataTypes.PASSWORD,
        dataTypes.LOCATION,
      ].includes(field.type) || field.type.type === 'belongsTo'

    if (field?.binding?.source?.sort) {
      const { reference, ...sort } = field.binding.source.sort
      sortValue = sort

      if (reference) {
        const { source, fallback } = reference
        locationFallback = fallback

        if (source) {
          const { binding, ...location } = source
          locationSource = location
        }
      }
    }

    return (
      <div onMouseDown={this.handleMouseDown}>
        <SimpleTextControl
          displayName="Label"
          value={field.label}
          name="label"
          onChange={this.handleChange}
        />
        {field.type === dataTypes.LOCATION ? (
          <IconRow
            displayName="Icon"
            name="icon"
            value={field.icon}
            onChange={this.handleChange}
          />
        ) : null}
        {showPlaceholder ? (
          <SimpleTextControl
            displayName="Placeholder"
            value={field.placeholder}
            name="placeholder"
            onChange={this.handleChange}
            placeholder="None"
          />
        ) : null}
        {field.type.type === 'belongsTo' && (
          <>
            <div className="child">
              <ListFilters
                appId={appId}
                componentId={componentId}
                object={object}
                binding={field.binding}
                onChange={this.handleChangeFilter}
                addLabel="Add Custom Filter"
                isForm
              />
            </div>
            <SelectSort
              value={sortValue}
              referenceValue={locationSource}
              fallbackValue={locationFallback}
              objectId={object.id}
              binding={field.binding}
              onChange={this.handleChangeSort}
              onChangeLocationSource={this.handleChangeSortLocationSource}
              onChangeLocationFallback={this.handleChangeSortLocationFallback}
            />
          </>
        )}
        {field.type === dataTypes.TEXT ? (
          <MenuControl
            options={[
              { label: 'Single Line', value: false },
              { label: 'Multi-line', value: true },
            ]}
            value={field.multiline || false}
            name="multiline"
            displayName="Input Type"
            onChange={this.handleChange}
          />
        ) : null}
        {field.type === dataTypes.DATE_ONLY ? (
          <MenuControl
            options={[
              { label: 'Date Picker', value: dataTypes.DATE_ONLY },
              { label: 'Date Text Input', value: 'dateTextInput' },
            ]}
            value={field.datePickerStyle || dataTypes.DATE_ONLY}
            name="datePickerStyle"
            displayName="Date Picker Style"
            onChange={this.handleChange}
          />
        ) : null}
        <ToggleField
          label="Required Error Text"
          value={field.required}
          inputValue={
            field.required ? field?.requiredText || '' : 'Not Required'
          }
          onChange={this.handleRequiredToggle}
          onInputChange={field.required ? this.handleRequiredTextChange : null}
          boxed
          hideLabelOnDisabled
        />
      </div>
    )
  }

  getCollectionField = () => {
    const { field, datasourceCollection } = this.props

    return datasourceCollection.fields[field.fieldId]
  }

  renderTitle = () => {
    const { onDelete } = this.props
    const field = this.getCollectionField()

    return (
      <div className="form-inspect-field-title">
        <span>{(field && field.name) || '[Deleted Column]'}</span>
        {onDelete ? (
          <IconButton type="trash-small" onClick={this.handleDelete} />
        ) : null}
      </div>
    )
  }

  render() {
    const { field, datasourceCollection } = this.props

    if (!field || !datasourceCollection) {
      return null
    }

    return (
      <div className="form-inspect-field-wrapper">
        <GroupedAccordion
          boxed
          className="form-inspect-field"
          group="form-inspect-field"
          itemId={field.fieldId}
          title={this.renderTitle()}
          renderChildren={() => this.renderChildren()}
        />
      </div>
    )
  }
}

export default FieldItem
