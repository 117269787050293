import React, { Component } from 'react'
import { actionTypes, transitions } from '@adalo/constants'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { SortableContainer } from '@adalo/react-sortable-hoc'

import { newActionItem } from 'utils/actions'
import { getDatasources } from 'ducks/apps/datasources'
import { getCustomActions, createCustomAction } from 'ducks/customActions'
import { getContextualObjects } from 'ducks/recommender'
import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'

import { getDatasourceType, getScreens, getUpgraded, getApp } from 'ducks/apps'
import { openAccordion } from 'ducks/accordions'
import {
  showModal,
  NEW_SCREEN_MODAL,
  NEW_TABLE,
  CUSTOM_ACTION_MODAL,
  CHOOSE_ZAP_MODAL,
  FEATURE_TEMPLATE_MODAL,
} from 'ducks/editor/modals'
import { getTrialState, isFeatureEnabled } from 'ducks/organizations'
import { getFeatureFlag } from 'ducks/featureFlags'

import { generateHandleTrial } from 'hooks/useHandleTrialOrUpgrade'

import Button from 'components/Shared/Button'

import { THEMES } from '../../../../constants'

import { findTemplate, createChatGPTCustomAction } from './template'
import ActionItem from './Action'
import getMenu from '../getMenu'

class ActionList extends Component {
  handleAdd = async opt => {
    const {
      fields,
      onAdd,
      openAccordion,
      showModal,
      appId,
      actions = [],
      paying,
      handleTrial,
      areCustomActionsEnabled,
      trialState,
      objectId,
    } = this.props

    let defaults = {}
    let firstLinkActionItemIndex = actions.length

    try {
      if (typeof opt === 'string') {
        defaults = { actionType: opt }
      } else if (opt && opt.target === 'new') {
        const {
          value: { target, transition },
        } = await showModal(NEW_SCREEN_MODAL)

        const options = {
          target,
          transition: transitions[transition] ?? null,
        }

        defaults = {
          options,
          actionType: actionTypes.NAVIGATE,
        }
      } else if (opt?.target === 'createNewCollection') {
        const options = (await showModal(NEW_TABLE)).value

        defaults = {
          options,
          actionType: actionTypes.CREATE_OBJECT,
        }
      } else if (opt?.target === 'chooseZap') {
        return showModal(CHOOSE_ZAP_MODAL, { zapType: opt?.type, appId })
      } else if (opt?.target === 'addFeatureTemplate') {
        const modalOptions = { appId, linkingObjectId: objectId }

        return showModal(FEATURE_TEMPLATE_MODAL, modalOptions)
      } else if (opt?.target === 'createNewCustomAction') {
        if (!areCustomActionsEnabled) {
          const callbackOptions = {
            fields,
            onAdd,
            firstLinkActionItemIndex,
          }

          handleTrial(trialState, paying, callbackOptions)

          return
        }

        let customActionId

        if (opt?.type === 'chatgpt') {
          const {
            createCustomAction,
            customActions,
            openaiApiKey,
            history,
            match: { params },
          } = this.props

          if (!openaiApiKey) {
            history.push(`/apps/${params.appId}/app-settings?active=apikeys`)

            return alert(
              'You must enter your OpenAI API key before you can use this action.'
            )
          }

          const chatGPTTemplate = createChatGPTCustomAction(openaiApiKey)
          const template = findTemplate(customActions)

          customActionId = template
            ? template.id
            : (await createCustomAction(appId, chatGPTTemplate))?.value?.id
        } else {
          customActionId = (await showModal(CUSTOM_ACTION_MODAL)).value
        }

        defaults = {
          actionType: actionTypes.CUSTOM,
          options: { customActionId },
        }
      } else if (
        opt?.actionType === actionTypes.CUSTOM ||
        opt?.actionType === actionTypes.AUTHENTICATE_EXTERNAL ||
        opt?.actionType === actionTypes.SIGNUP_EXTERNAL
      ) {
        defaults = {
          actionType: opt.actionType,
          options: { customActionId: opt.options.id },
        }
      } else if (opt?.actionType) {
        defaults = { ...opt }
      } else {
        defaults = {
          actionType: actionTypes.NAVIGATE,
          options: opt,
        }
      }
    } catch (err) {
      return
      // Do nothing - Modal was closed early
    }

    const action = newActionItem(defaults)
    openAccordion('action-item', action.id)

    for (const item in actions) {
      if (
        actions[item].actionType === 'navigate' ||
        actions[item].actionType === 'externalLink'
      ) {
        firstLinkActionItemIndex = item

        break
      }
    }

    if (onAdd) {
      onAdd(action)
    }

    if (fields) {
      if (
        action.actionType !== 'navigate' &&
        action.actionType !== 'externalLink'
      ) {
        const newIndex = firstLinkActionItemIndex
        fields.splice(newIndex, 0, action)
      } else {
        fields.push(action)
      }
    }
  }

  getMenuOptions = () => {
    const menuOptions = getMenu(this.props)

    return menuOptions
  }

  render() {
    const {
      fields,
      appId,
      componentId,
      objectId,
      actions,
      reference,
      form,
      handleTrial,
      omitContextualTables,
    } = this.props

    return (
      <>
        <div className="action-actions-list">
          {fields &&
            fields.map((field, i) => (
              <ActionItem
                key={actions[i] && actions[i].id}
                index={i}
                appId={appId}
                componentId={componentId}
                objectId={objectId}
                action={actions[i]}
                field={field}
                reference={reference}
                handleTrial={handleTrial}
                onRemove={() => fields.remove(i)}
                formName={form}
                omitContextualTables={omitContextualTables}
              />
            ))}
        </div>
        <MultiMenuTrigger
          menu={this.getMenuOptions}
          onSelect={this.handleAdd}
          rowHeight={40}
          menuTheme={THEMES.ACTION}
          childWidth={240}
        >
          <Button type="button" icon="plus-small" listAddButton gray>
            Add Another Action
          </Button>
        </MultiMenuTrigger>
      </>
    )
  }
}

const mapStateToProps = (
  state,
  { appId, componentId, objectId, actions, reference, dummyActions }
) => {
  const prevActionId =
    actions?.[actions.length - 1]?.id || dummyActions?.[0]?.id

  const contextOptions = getContextualObjects(
    state,
    appId,
    componentId,
    objectId,
    undefined,
    prevActionId,
    reference,
    { includeCurrentAction: true }
  )()

  const datasources = getDatasources(state, appId)
  const datasourceType = getDatasourceType(state, appId)
  const screens = getScreens(state, appId)
  const customActions = getCustomActions(state, appId)

  const { trialState } = getTrialState(state)

  const isPaying = getUpgraded(state, appId)
  const paying = isPaying || trialState === 'during'
  const app = getApp(state, appId)

  const googleApiKey = getThirdPartyApiKeyFor(state, appId, 'google')
  const googleApiKeyIsValid = googleApiKey && googleApiKey.isValid

  const openaiApiKey = getThirdPartyApiKeyFor(state, appId, 'openai')?.key

  const shouldCancelStart = e => {
    const composedPath = e.composedPath()

    if (
      !composedPath.find(
        el => el.dataset && el.dataset.sortablehandle === 'true'
      )
    ) {
      return true
    }
  }

  return {
    areCustomActionsEnabled: isFeatureEnabled(state, 'customIntegrations'),
    isGeolocationEnabled: isFeatureEnabled(state, 'geolocation'),
    trialState,
    shouldCancelStart,
    contextOptions,
    datasourceType,
    datasources,
    screens,
    customActions,
    paying,
    appId,
    app,
    googleApiKeyIsValid,
    openaiApiKey,
    hasFeatureTemplatesAction: getFeatureFlag(state, 'hasFeatureTemplates'),
  }
}

const mapDispatchToProps = (dispatch, { appId }) => {
  const handleTrial = (
    trialState,
    isPaidOrg,
    callbackOptions,
    type = 'customActions'
  ) => {
    const handle = generateHandleTrial({
      dispatch,
      appId,
      trialState,
      type,
      callbackOptions,
      isPaidOrg,
    })

    handle()
  }

  return {
    handleTrial,
    openAccordion: (...props) => dispatch(openAccordion(...props)),
    showModal: (...props) => dispatch(showModal(...props)),
    createCustomAction: (...props) => dispatch(createCustomAction(...props)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SortableContainer(ActionList))
)
