import deepEqual from 'deep-equal'

type Reference = unknown & {
  selector: unknown
}

export const referenceComparator = (
  ref1: Reference,
  ref2: Reference
): boolean => {
  if (!ref1 || !ref2 || !ref1.selector || !ref2.selector) {
    return deepEqual(ref1, ref2)
  }

  return deepEqual(ref1.selector, ref2.selector)
}
