import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, change } from 'redux-form'

import {
  TRANSITION_TYPE_OPTIONS,
  NAVIGATION_BACK,
  actionTypes,
} from '@adalo/constants'

import { getLinkOptions, setDefaultTransitionToNone } from 'utils/actions'

import { showModal, NEW_SCREEN_MODAL } from 'ducks/editor/modals'
import { getScreens, getAssociatedAppScreens, getApp } from 'ducks/apps'
import { getParams } from 'ducks/apps/params'

import MenuControl from 'components/Editor/Inspect/Libraries/MenuControl'
import ControlField from 'components/Shared/Forms/ControlField'
import ImplicitParams from 'components/Editor/Params/ImplicitParams'

import Params from './Params'

import { THEMES } from '../../../../constants'

class NavigateOptions extends Component {
  getTransitionOptions() {
    const { options, app } = this.props
    const { target } = options || {}

    if (!target || target === NAVIGATION_BACK) {
      return []
    }

    if (app?.magicLayout) {
      return setDefaultTransitionToNone(TRANSITION_TYPE_OPTIONS)
    }

    return TRANSITION_TYPE_OPTIONS
  }

  handleChangeValue = value => {
    const { change, formName, field } = this.props

    if (value === actionTypes.EXTERNAL_LINK) {
      change(formName, `${field}.actionType`, value)
    }
  }

  render() {
    const {
      field,
      options,
      params,
      appId,
      componentId,
      objectId,
      omitContextualTables,
    } = this.props

    const target = options && options.target

    const showParams = params.length > 0 && target !== NAVIGATION_BACK

    return (
      <div className="action-form-navigate">
        <div className="form-inspect-field-wrapper">
          <Field
            name={`${field}.options.target`}
            component={LinkMenu}
            appId={appId}
            componentId={componentId}
            displayName="Screen"
            onChangeValue={this.handleChangeValue}
            menuTheme={THEMES.ACTION}
          />
          <ControlField
            autoSelect
            disabled={options.target === NAVIGATION_BACK}
            name={`${field}.options.transition`}
            component={MenuControl}
            options={this.getTransitionOptions()}
            placeholder="Auto"
            displayName="Transition"
            menuTheme={THEMES.ACTION}
          />
          <div className="multi-menu-link-from-source">
            <ImplicitParams
              appId={appId}
              componentId={options.target}
              displayFromSource
              screenId={componentId}
              originComponent={objectId}
              omitContextualTables={omitContextualTables}
            />
          </div>
        </div>
        {showParams ? <Params {...this.props} /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { appId, options } = ownProps
  const target = options && options.target

  return {
    app: getApp(state, appId),
    params: getParams(state, appId, target),
  }
}

export default connect(mapStateToProps, { change })(NavigateOptions)

class NavigateSelect extends Component {
  getOptions = () => {
    const {
      screens,
      componentId,
      allScreens,
      includeAssociated,
      associatedApps,
      app,
    } = this.props

    const linkOptions = getLinkOptions(screens, componentId, app, {
      allScreens,
    })

    if (includeAssociated && associatedApps.length > 0) {
      if (linkOptions.length > 0) {
        linkOptions.push(null)
      }

      const associatedOptions = associatedApps.map(app => ({
        label: app.name,
        children: getLinkOptions(app.screens, null, app, {
          skipNew: true,
          appId: app.id,
        }),
      }))

      linkOptions.push({
        label: 'Other Apps',
        children: associatedOptions,
      })
    }

    return linkOptions
  }

  getLabel = value => {
    let { screens, associatedApps } = this.props
    let appName

    if (value === NAVIGATION_BACK) {
      return '← Back'
    }

    if (value && typeof value === 'object') {
      const app = associatedApps?.filter(a => a.id === value.appId)[0]

      screens = app && app.screens
      appName = app && app.name
      value = value.screenId
    }

    const screenObj = screens?.filter(s => s.id === value)[0]

    const screenName =
      screenObj?.name ?? (value ? '⚠️ Link to Deleted Screen' : undefined)

    return appName ? `${appName} > ${screenName}` : screenName
  }

  handleChange = async value => {
    const {
      input: { onChange },
      showModal,
      onChangeValue,
    } = this.props

    if (value === 'new') {
      try {
        value = (await showModal(NEW_SCREEN_MODAL)).value
      } catch (err) {
        return
      }
    }

    if (onChangeValue) {
      onChangeValue(value)
    }

    onChange(value)
  }

  render() {
    let { input, ...props } = this.props
    input = { ...input, onChange: this.handleChange }

    return (
      <MenuControl
        {...props}
        options={this.getOptions}
        getLabel={this.getLabel}
        input={input}
      />
    )
  }
}

const menuMapState = (state, { appId, includeAssociated }) => ({
  screens: getScreens(state, appId),
  app: getApp(state, appId),
  associatedApps: includeAssociated
    ? getAssociatedAppScreens(state, appId)
    : null,
})

export const LinkMenu = connect(menuMapState, { showModal })(NavigateSelect)
