import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { dataTypes } from '@adalo/constants'
import { getLibraryBindingSuggestions } from 'ducks/recommender'
import { GroupedAccordion } from 'components/Shared/Accordion'
import BindableTextControl, {
  FormulaControl,
} from 'components/Shared/BindableTextControl'
import { IconButton } from 'components/Shared/Icon'
import { getLabel } from 'utils/sources'
import MenuControl from '../Libraries/MenuControl'

class HiddenField extends Component {
  handleChange = value => {
    const { index, onChange } = this.props

    if (value && 'value' in value) {
      value = value.value
    }

    onChange(index, { value })
  }

  handleDelete = () => {
    const { index, onDelete } = this.props

    onDelete(index)
  }

  getCollectionField = () => {
    const { field, collection } = this.props

    return collection.fields[field.fieldId]
  }

  renderTitle = () => {
    const { onDelete, field, getLabel } = this.props
    let collectionField = this.getCollectionField()

    if (!collectionField) {
      collectionField = { name: '[Deleted]' }
    }

    return (
      <div className="form-inspect-field-title form-inspect-hidden-field-title">
        <div className="form-inspect-field-title-sub">
          <p>{collectionField.name}</p>
          <p className="form-inspect-field-subtitle">{getLabel(field.value)}</p>
        </div>
        {onDelete ? (
          <IconButton type="trash-small" onClick={this.handleDelete} />
        ) : null}
      </div>
    )
  }

  renderChildren = () => {
    const { field, options, getLabel, object } = this.props
    const { value } = field

    switch (field.type) {
      case dataTypes.TEXT:
      case dataTypes.PASSWORD:
        return (
          <div className="form-inspect-hidden-field-value">
            <BindableTextControl
              displayName="Value"
              name="value"
              value={value}
              onChange={this.handleChange}
              comparator={deepEqual}
              getLabel={getLabel}
              objectId={object.id}
            />
          </div>
        )
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <div className="form-inspect-hidden-field-value">
            <FormulaControl
              dataType={field.type}
              displayName="Value"
              name="value"
              value={value}
              onChange={this.handleChange}
              comparator={deepEqual}
              getLabel={getLabel}
              objectId={object.id}
              placeholder="0"
            />
          </div>
        )
      case dataTypes.BOOLEAN:
      default:
        return (
          <div className="form-inspect-hidden-field-value">
            <MenuControl
              options={options}
              name="value"
              displayName="Value"
              value={value}
              getLabel={getLabel}
              comparator={deepEqual}
              onChange={this.handleChange}
            />
          </div>
        )
    }
  }

  render() {
    const { field } = this.props

    return (
      <div className="form-inspect-field-wrapper">
        <GroupedAccordion
          boxed
          className="form-inspect-field"
          group="form-inspect-field"
          itemId={field.fieldId}
          title={this.renderTitle()}
          renderChildren={this.renderChildren}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { field, object, appId, componentId }) => ({
  options: getLibraryBindingSuggestions(state, appId, componentId, object.id, [
    field.type,
  ]),
  getLabel: val => getLabel(state, val, appId, componentId),
})

const WrappedHiddenField = connect(mapStateToProps)(HiddenField)

export default WrappedHiddenField
