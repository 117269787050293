import { dataTypes } from '@adalo/constants'
import { DEFAULT_ZOOM, scaleValue } from './zoom'
import { updateObjectWidth } from './text'

const LABEL_PAD = 10
const ROW_PAD = 25
const SUBMIT_PAD = 20
const SECONDARY_BUTTON_PAD = 20
const IMAGE_UPLOAD_HEIGHT = 212
const FILE_UPLOAD_HEIGHT = 80
const CHECKBOX_HEIGHT = 20
const DEFAULT_BUTTON_PADDING = 10
const DEFAULT_BUTTON_FONT_SIZE = 14

const SUBMIT_BUTTON_KEY = 'submit-button'
const SECONDARY_BUTTON_KEY = 'secondary-button'

export const FormButtonKeys = {
  SUBMIT: SUBMIT_BUTTON_KEY,
  SECONDARY: SECONDARY_BUTTON_KEY,
}

export const calculateLayout = (form, zoom = DEFAULT_ZOOM) => {
  const { fields, submitButton, secondaryButton } = form
  const elements = []

  const submitButtonPadding = submitButton.padding ?? DEFAULT_BUTTON_PADDING
  const submitFontSize = submitButton.fontSize ?? DEFAULT_BUTTON_FONT_SIZE
  const secondaryButtonPadding = secondaryButton
    ? secondaryButton.padding ?? DEFAULT_BUTTON_PADDING
    : 0
  const secondaryButtonFontSize = secondaryButton
    ? secondaryButton.fontSize ?? DEFAULT_BUTTON_FONT_SIZE
    : 0

  let cursor = 0

  for (const field of fields) {
    const itm = { start: cursor }

    let widthOffset = 0

    if (field.type === dataTypes.BOOLEAN) {
      widthOffset = 24
    }

    const fakeLabel = {
      text: field.label,
      type: 'label',
      fontSize: 14,
      width: form.width - widthOffset,
      multiline: true,
    }

    const labelHeight = updateObjectWidth(fakeLabel).height

    const fontSize = getFormFieldStyle(form, 16, 'inputs', 'fontSize')
    const inputPadding = getFormFieldStyle(form, 10, 'inputs', 'padding')
    const inputHeight = 2 * inputPadding + 1.3 * fontSize
    const multilineHeight = 2 * inputPadding + 1.3 * 2 * fontSize

    // Label
    if (field.label) {
      itm.label = cursor

      if (field.type === dataTypes.BOOLEAN) {
        const diff = Math.round(
          Math.max(0, (labelHeight - CHECKBOX_HEIGHT) / 2)
        )

        cursor += scaleValue(diff, zoom)
      } else {
        cursor += scaleValue(labelHeight + LABEL_PAD, zoom)
      }
    }

    itm.input = cursor

    if (field.type === dataTypes.IMAGE) {
      cursor += scaleValue(IMAGE_UPLOAD_HEIGHT, zoom)
    } else if (field.type === dataTypes.FILE) {
      cursor += scaleValue(FILE_UPLOAD_HEIGHT, zoom)
    } else if (field.type === dataTypes.BOOLEAN) {
      cursor += scaleValue(CHECKBOX_HEIGHT, zoom)
    } else if (field.type === dataTypes.TEXT && field.multiline) {
      cursor += scaleValue(multilineHeight, zoom)
    } else {
      cursor += scaleValue(inputHeight, zoom)
    }

    if (field.label && field.type === dataTypes.BOOLEAN) {
      const diff = Math.round(Math.max(0, (labelHeight - CHECKBOX_HEIGHT) / 2))
      cursor += scaleValue(diff, zoom)
    }

    elements.push(itm)
    cursor += scaleValue(ROW_PAD, zoom)
  }

  // Submit button
  const submitItm = { key: SUBMIT_BUTTON_KEY, start: cursor }

  if (cursor > 0) {
    cursor += scaleValue(SUBMIT_PAD, zoom)
  }

  submitItm.button = cursor
  elements.push(submitItm)
  cursor += scaleValue(1.4 * submitFontSize + 2 * submitButtonPadding, zoom)

  // Secondary button
  if (secondaryButton && secondaryButton.enabled) {
    const secondaryButtonItm = { key: SECONDARY_BUTTON_KEY, start: cursor }

    if (cursor > 0) {
      cursor += scaleValue(SECONDARY_BUTTON_PAD, zoom)

      secondaryButtonItm.button = cursor
      elements.push(secondaryButtonItm)
      cursor += scaleValue(
        1.4 * secondaryButtonFontSize + 2 * secondaryButtonPadding,
        zoom
      )
    }
  }

  const height = Math.ceil(cursor)

  return { elements, height }
}

export const posInt = numString => {
  if (numString === '') {
    return numString
  }

  let num = parseInt(numString) || 0
  num = Math.max(0, num)

  return num.toFixed(0)
}

export const getFormFieldStyle = (formObject, defaultValue, ...path) => {
  const fieldStyles = formObject.fieldStyles || {}
  let value = fieldStyles

  for (const itm of path) {
    if (!value) {
      value = {}
    }

    value = value[itm]
  }

  if (value === undefined) {
    return defaultValue
  }

  return value
}
