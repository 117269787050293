import { uniqueElements, traverse } from '@adalo/utils'
import {
  COMPONENT,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  LABEL,
  SECTION,
  LAYOUT_SECTION,
  ELLIPSE,
  SHAPE,
  IMAGE,
  GROUP,
  LIST,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  SELECT,
  FORM,
  WEB_VIEW,
  LINE,
  LOCATION_INPUT,
  VIDEO,
  TABLE,
  backgroundStyle,
  borderPosition,
  borderStyle,
  inputTypes,
  bindingTypes,
  dataTypes,
  positioning,
  statusBarStyles,
  imageResize,
  DeviceWidth,
} from '@adalo/constants'

import { isSupportedOnWeb } from './platform'

export const OBJECTS_SUPPORTING_BORDER_RADIUS_CONTROL = new Set([
  SECTION,
  IMAGE,
])

const positionFeatures = {
  x: true,
  y: true,
  width: true,
  height: true,
}

const genericFeatures = {
  ...positionFeatures,
  name: true,
  opacity: true,
  positioning: true,
  hidden: true,
}

const textFeatures = {
  fontSize: true,
  fontFamily: true,
  fontWeight: true, // 100 - 900
  fontStyle: true, // 'normal' or 'italic'
  color: true,
}

const sectionFeatures = {
  backgroundStyle: true,
  backgroundColor: true,
  borderColor: true,
  borderStyle: true,
  borderWidth: true,
  borderRadius: true,
}

export const defaultIndependentRounding = {
  borderTopLeftRadius: null,
  borderTopRightRadius: null,
  borderBottomLeftRadius: null,
  borderBottomRightRadius: null,
}

export const features = {
  [LABEL]: {
    ...genericFeatures,
    fontStyle: true, // 'normal' or 'italic'
    autoWidth: true,
    multiline: true,
    selectable: true,
    maxLength: true,
  },
  [SECTION]: {
    ...genericFeatures,
    borderPosition: true,
    shadow: true,
  },
  [LAYOUT_SECTION]: {
    ...genericFeatures,
    borderPosition: true,
    shadow: true,
  },
  [LINE]: {
    ...genericFeatures,
    borderPosition: true,
  },
  [ELLIPSE]: {
    ...genericFeatures,
    shadow: true,
  },
  [SHAPE]: {
    ...genericFeatures,
    points: true,
    isClosed: true,
  },
  [IMAGE]: {
    ...genericFeatures,
    ...sectionFeatures,
    borderRadius: true,
  },
  [VIDEO]: {
    ...genericFeatures,
    ...sectionFeatures,
    borderRadius: true,
    shadow: true,
  },
  [GROUP]: {
    ...genericFeatures,
    children: true,
  },
  [LIST]: {
    ...genericFeatures,
    children: true,
    columnCount: true,
    masonry: true,
  },
  [INPUT]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    autoFocus: true,
    placeholderColor: true,
    multiline: true,
    padding: true,
    shadow: true,
    maxLength: true,
  },
  [COMPONENT]: {
    ...positionFeatures,
    backgroundColor: true,
    statusBarStyle: true,
    reverseScroll: true,
  },
  [IMAGE_UPLOAD]: {
    ...genericFeatures,
    borderRadius: true,
  },
  [FILE_UPLOAD]: {
    ...genericFeatures,
  },
  [DATE_PICKER]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    padding: true,
    shadow: true,
  },
  [SELECT]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    padding: true,
    shadow: true,
  },
  [FORM]: {
    ...genericFeatures,
    datasourceId: true,
    tableId: true,
    submitButton: true,
    secondaryButton: false,
    fields: true,
    fieldStyles: true,
  },
  [WEB_VIEW]: {
    ...genericFeatures,
  },
  [TABLE]: {
    ...genericFeatures,
    tableData: {
      tableStyles: true,
      borderColor: true,
      borderStyle: true,
      borderWidth: true,
      borderRadius: true,
      shadow: true,
    },
  },
  [COMPONENT_INSTANCE]: {
    ...genericFeatures,
  },
  [LIBRARY_COMPONENT]: {
    ...genericFeatures,
  },
  [LOCATION_INPUT]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    backgroundStyle: false,
    placeholderColor: true,
    autoFocus: true,
    padding: true,
    shadow: true,
  },
}

const genericDefaults = {
  name: null,
  opacity: 1,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  positioning: positioning.DEFAULT,
  hidden: false,
}

const textDefaults = {
  fontSize: 16,
  fontWeight: '400',
  fontStyle: 'normal',
  fontFamily: '@body',
  color: '#000',
}

const shapeDefaults = {
  backgroundStyle: backgroundStyle.COLOR,
  backgroundColor: '#ddd',
  borderStyle: borderStyle.SOLID,
  borderWidth: 1,
  borderColor: '#999',
}

const shadowDefaults = {
  shadow: {
    enabled: false,
    color: 'rgba(0, 0, 0, 0.2)',
    x: 0,
    y: 2,
    size: 4,
  },
}

const sectionDefaults = {
  ...shapeDefaults,
  borderRadius: 0,
}

/**
 * @type {Record<import('ducks/editor/types/ObjectType').ObjectType, Partial<import('./responsiveTypes').EditorObject>>}
 */
export const defaults = {
  [LABEL]: {
    ...genericDefaults,
    ...textDefaults,
    color: '@text',
    textAlignment: 'left',
    text: 'Enter Text',
    width: 343,
    height: 19,
    fontSize: 16,
    fontWeight: 500,
    autoWidth: false,
    multiline: true,
    selectable: false,
    maxLength: null,
  },
  [SECTION]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    ...defaultIndependentRounding,
    borderPosition: borderPosition.CENTER,
    backgroundColor: '#e0e0e0',
    borderStyle: borderStyle.NONE,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 0,
    width: 343,
    height: 100,
  },
  [LAYOUT_SECTION]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    imageResize: imageResize.COVER,
    backgroundStyle: backgroundStyle.NONE,
    borderPosition: borderPosition.CENTER,
    backgroundColor: '#ffffff00',
    borderStyle: borderStyle.NONE,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 0,
    width: 390,
    height: 200,
    snappingRules: {
      snap: true,
      snapType: 'screenEdge',
      lock: {
        left: 0,
        right: 0,
      },
    },
    shadow: {
      ...shadowDefaults.shadow,
      enabled: true,
      color: '#00000020',
    },
  },
  [LINE]: {
    ...genericDefaults,
    ...sectionDefaults,
    borderPosition: borderPosition.CENTER,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#000',
    width: 343,
    height: 1,
  },
  [ELLIPSE]: {
    ...genericDefaults,
    ...shapeDefaults,
    ...shadowDefaults,
    width: 100,
    height: 100,
    borderWidth: 0,
    backgroundColor: '#e0e0e0',
  },
  [SHAPE]: {
    ...genericDefaults,
    ...shapeDefaults,
    points: [],
    isClosed: false,
    width: 1,
    height: 1,
  },
  [IMAGE]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...defaultIndependentRounding,
    backgroundStyle: backgroundStyle.NONE,
    borderStyle: borderStyle.NONE,
    borderRadius: 0,
    imageResize: imageResize.COVER,
    width: 375,
    height: 210,
  },
  [VIDEO]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    backgroundStyle: backgroundStyle.COLOR,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    borderStyle: borderStyle.NONE,
    borderRadius: 0,
    imageResize: imageResize.CONTAIN,
    videoType: 'url',
    videoBinding: {
      options: {
        placeholderImageEnabled: false,
      },
    },
    width: 375,
    height: 210,
    videoControls: true,
    videoAutoPlay: false,
    videoLoop: false,
    videoMuted: false,
  },
  [GROUP]: {
    ...genericDefaults,
    children: [],
  },
  [LIST]: {
    ...genericDefaults,
    width: 375,
    height: 80 * 3 + 8 * 3,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    children: [],
    rowMargin: 8,
    columnCount: 1,
    masonry: true,
  },
  [INPUT]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    inputType: inputTypes.DEFAULT,
    autoFocus: false,
    placeholder: 'Enter Text',
    placeholderColor: '#a9a9a9',
    defaultValue: '',
    backgroundColor: '#fff',
    padding: 8,
    width: 343,
    height: 40,
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
    multiline: false,
    maxLength: null,
  },
  [COMPONENT]: {
    ...genericDefaults,
    backgroundColor: '#fff',
    statusBarStyle: statusBarStyles.DARK_CONTENT,
    children: [],
    width: 375,
    height: 667,
    reverseScroll: false,
    x: undefined,
    y: undefined,
  },
  [IMAGE_UPLOAD]: {
    ...genericDefaults,
    width: 343,
    height: 210,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 0,
  },
  [FILE_UPLOAD]: {
    ...genericDefaults,
    width: 343,
    height: 210,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
  },
  [DATE_PICKER]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    padding: 10,
    width: 343,
    height: 40,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
  },
  [SELECT]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    padding: 10,
    name: 'Dropdown',
    placeholder: 'Select...',
    width: 343,
    height: 40,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
  },
  [FORM]: {
    ...genericDefaults,
    width: 343,
    height: 338,
    submitButton: {
      backgroundColor: '@secondary',
      fontFamily: '@body',
      fontWeight: '500',
    },
    secondaryButton: {
      enabled: false,
      text: 'CANCEL',
      color: '@text',
      fontFamily: '@body',
      fontWeight: '500',
      borderColor: '@text',
      borderWidth: 1,
      backgroundColor: '@background',
    },
    fieldStyles: {
      inputs: {
        color: '@text',
        accentColor: '@secondary',
        errorColor: 'red',
        fontFamily: '@body',
      },
      labels: {
        color: '@text',
        fontFamily: '@body',
      },
    },
  },
  [WEB_VIEW]: {
    ...genericDefaults,
    width: 375,
    height: 591,
  },
  [TABLE]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    backgroundColor: '#FFFFFF',
    borderPosition: borderPosition.CENTER,
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 10,
    fontFamily: '@body',
    // Start table-specific props
    tableColumnBackgroundColor: '#EEEEEE',
    tableColumnLineColor: '#BDBDBD',
    tableColumnFontColor: '#000000',
    tableRowHoverBackgroundColor: '#F5F5F5',
    tableRowFontColor: '#424242',
    tableRowLineColor: '#F0F0F0',
    // End table-specific props
    width: 360,
    height: 3 * 55 + 50,
    attributes: { pageSize: 10 },
    fields: [],
    emptyStateTitleText: 'No Items',
    emptyStateBodyText: 'Items that get added to the table will be shown here',
    styles: {
      emptyStateTitleText: {
        fontSize: 18,
        fontWeight: '500',
        fontStyle: 'normal',
        fontFamily: '@heading',
        color: '#656565',
      },
      emptyStateBodyText: {
        fontSize: 12,
        fontWeight: '300',
        fontStyle: 'normal',
        fontFamily: '@body',
        color: '#757575',
      },
    },
    subType: LIBRARY_COMPONENT,
  },
  [COMPONENT_INSTANCE]: {
    ...genericDefaults,
  },
  [LIBRARY_COMPONENT]: {
    ...genericDefaults,
  },
  [LOCATION_INPUT]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    padding: 10,
    placeholder: 'Search by name or address...',
    width: 343,
    height: 40,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
    icon: 'location_on',
  },
}

export const getNewScreenSize = (platform, magicLayout) => {
  if (isSupportedOnWeb(platform)) {
    return magicLayout
      ? {
          width: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
          height: 982,
        }
      : {
          width: 1200,
          height: 800,
        }
  }

  return {
    width: 375,
    height: 667,
  }
}

const baseBindingTypes = [bindingTypes.VISIBILITY]

export const allowedBindingTypes = {
  [LABEL]: [...baseBindingTypes, bindingTypes.SET_TEXT],
  [SECTION]: [...baseBindingTypes],
  [LAYOUT_SECTION]: [...baseBindingTypes],
  [LINE]: [...baseBindingTypes],
  [ELLIPSE]: [...baseBindingTypes],
  [SHAPE]: [...baseBindingTypes],
  [IMAGE]: [...baseBindingTypes, bindingTypes.SET_IMAGE],
  [VIDEO]: [...baseBindingTypes, bindingTypes.SET_IMAGE],
  [GROUP]: [...baseBindingTypes],
  [LIST]: [...baseBindingTypes, bindingTypes.LIST],
  [INPUT]: [...baseBindingTypes, bindingTypes.SET_TEXT],
  [FORM]: [...baseBindingTypes],
  [LIBRARY_COMPONENT]: [...baseBindingTypes],
  [IMAGE_UPLOAD]: [...baseBindingTypes, bindingTypes.SET_IMAGE],
  [IMAGE_UPLOAD]: [...baseBindingTypes],
  [DATE_PICKER]: [...baseBindingTypes, bindingTypes.SET_DATE],
  [SELECT]: [...baseBindingTypes, bindingTypes.LIST],
  [WEB_VIEW]: [...baseBindingTypes, bindingTypes.SET_URI],
  [FILE_UPLOAD]: [...baseBindingTypes],
  [LOCATION_INPUT]: [...baseBindingTypes],
}

export const bindingDataTypes = {
  [bindingTypes.SET_TEXT]: [
    dataTypes.TEXT,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.NUMBER,
  ],
  [bindingTypes.LIST]: [dataTypes.LIST],
  [bindingTypes.SET_IMAGE]: [dataTypes.IMAGE],
  [bindingTypes.VISIBILITY]: [
    dataTypes.TEXT,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.NUMBER,
    dataTypes.IMAGE,
    dataTypes.LOCATION,
    dataTypes.BOOLEAN,
    dataTypes.LIST,
  ],
  [bindingTypes.SET_DATE]: [dataTypes.DATE, dataTypes.DATE_ONLY],
  [bindingTypes.SET_URI]: [dataTypes.TEXT],
  [bindingTypes.LIBRARY_PROP]: [
    dataTypes.TEXT,
    dataTypes.NUMBER,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.IMAGE,
    dataTypes.BOOLEAN,
    dataTypes.OBJECT,
    dataTypes.LIST,
    dataTypes.FILE,
    dataTypes.LOCATION,
  ],
}

export const getDataTypes = (bindingTypes = []) => {
  let types = []

  bindingTypes.forEach(bindingType => {
    types = types.concat(bindingDataTypes[bindingType] || [])
  })

  return uniqueElements(types)
}

export const dataTypeAllowed = (bindingTypes, dataType) => {
  const dataTypes = getDataTypes(bindingTypes)

  return dataTypes.indexOf(dataType) !== -1
}

export const bestBindingType = (bindingTypes, dataType, allowNull = false) => {
  bindingTypes = sortBindingTypes(bindingTypes)

  for (const bindingType of bindingTypes) {
    if (bindingDataTypes[bindingType].indexOf(dataType) !== -1) {
      return bindingType
    }
  }

  if (!allowNull) {
    throw new Error(
      `Cannot get binding type for data type '${dataType}' ` +
        `(${JSON.stringify(bindingTypes)})`
    )
  }

  return null
}

export const sortBindingTypes = types => {
  const sortedTypes = [
    bindingTypes.LIBRARY_PROP,
    bindingTypes.SET_DATE,
    bindingTypes.SET_DATE,
    bindingTypes.SET_TEXT,
    bindingTypes.SET_IMAGE,
    bindingTypes.LIST,
    bindingTypes.SET_URI,
    bindingTypes.VISIBILITY,
    bindingTypes.SET_PROPERTY,
  ]

  return sortedTypes.filter(type => {
    return types.indexOf(type) !== -1
  })
}

export const getAllKeys = objects => {
  const keys = {}

  for (const obj of objects) {
    for (const key of Object.keys(obj)) {
      keys[key] = true
    }
  }

  return Object.keys(keys)
}

export const commonValues = (objects, getStateDeviceObject) => {
  const keys = getAllKeys(objects)
  let initialObject = objects[0]

  if (getStateDeviceObject) {
    initialObject = getStateDeviceObject(initialObject)
  }

  const values = { ...initialObject }

  for (const obj of objects) {
    let object = obj

    if (getStateDeviceObject) {
      object = getStateDeviceObject(obj)
    }

    for (const key of keys) {
      if (!(key in object) || object[key] !== values[key]) {
        delete values[key]
      }
    }
  }

  return values
}

export const convertType = (object, newType) => {
  const newObject = {}

  for (const key in object) {
    if (features[newType][key]) {
      newObject[key] = object[key]
    }
  }

  return {
    ...defaults[newType],
    ...newObject,
    id: object.id,
    type: newType,
  }
}

export const indexByType = objects => {
  const result = {}

  traverse(objects, obj => {
    const { id, type } = obj

    if (!result[type]) {
      result[type] = []
    }

    result[type].push(id)
  })

  return result
}

export const getInputDataType = inputObject => {
  switch (inputObject.type) {
    case DATE_PICKER:
      return dataTypes.DATE
    case IMAGE_UPLOAD:
      return dataTypes.IMAGE
    case FILE_UPLOAD:
      return dataTypes.FILE
    case LOCATION_INPUT:
      return dataTypes.LOCATION
    default:
      return dataTypes.TEXT
  }
}

// POLYFILL
if (!Object.values) Object.values = obj => Object.keys(obj).map(e => obj[e])

export const deepGet = (obj, key = []) => {
  if (key.length === 0) {
    return obj
  }

  if (!obj) {
    return undefined
  }

  return deepGet(obj[key[0]], key.slice(1))
}

export const deepSet = (obj, key = [], value) => {
  if (key.length === 0) {
    return value
  }

  if (!obj) {
    obj = {}
  }

  return {
    ...obj,
    [key[0]]: deepSet(obj[key[0]], key.slice(1), value),
  }
}
