import React, { Component } from 'react'
import { SortableContainer, SortableElement } from '@adalo/react-sortable-hoc'
import FieldItem from './FieldItem'

const SortableFieldItem = SortableElement(FieldItem)

class FieldItemsList extends Component {
  render() {
    const {
      items,
      collection,
      datasource,
      onChange,
      onDelete,
      appId,
      object,
      componentId,
    } = this.props

    if (!datasource) {
      return null
    }

    return (
      <div>
        {items.map((field, i) => (
          <SortableFieldItem
            key={field.fieldId}
            appId={appId}
            object={object}
            componentId={componentId}
            fieldIndex={i}
            index={i}
            field={field}
            datasource={datasource}
            datasourceCollection={collection}
            onChange={onChange}
            onDelete={onDelete}
          />
        ))}
      </div>
    )
  }
}

const SortableFieldItemsList = SortableContainer(FieldItemsList)

export default SortableFieldItemsList
