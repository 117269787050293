import getContainingScreen from 'ducks/editor/objects/helpers/getContainingScreen'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import { EditorObject } from 'utils/responsiveTypes'
import { getContainerChildrenFromSection } from '..'
import createEmptyLayoutSection from './createEmptyLayoutSection'
import createLayoutSectionFromPrebuilt from './createLayoutSectionFromPrebuilt'

type Result = { list: ObjectList; pathMap: ObjectPathMap }

export const createLayoutSection = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  parentId: string,
  newObject: EditorObject,
  isCopy: boolean
): Result => {
  let updatedList = list
  let updatedPathMap = pathMap
  const screen = getContainingScreen(list, pathMap, parentId)
  const innerSectionChildren = getContainerChildrenFromSection(newObject)
  const hasInnerSectionChildren =
    innerSectionChildren && innerSectionChildren.length > 0

  if (hasInnerSectionChildren) {
    // Create the layout section with children
    ;({ list: updatedList, pathMap: updatedPathMap } =
      createLayoutSectionFromPrebuilt(list, pathMap, screen, newObject, isCopy))
  } else {
    // Create a new empty layout section
    ;({ list: updatedList, pathMap: updatedPathMap } = createEmptyLayoutSection(
      updatedList,
      pathMap,
      screen,
      newObject
    ))
  }

  return { list: updatedList, pathMap: updatedPathMap }
}
