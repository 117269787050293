import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableElement } from '@adalo/react-sortable-hoc'
import { isEmpty as _isEmpty } from 'lodash'

import { actionTypes } from '@adalo/constants'

import { actionName } from 'utils/naming'
import { actionSubtitle } from 'utils/actionNames'

import { getDatasourceType } from 'ducks/apps'
import { showModal, CUSTOM_ACTION_MODAL } from 'ducks/editor/modals'
import { getCustomAction } from 'ducks/customActions'
import { isFeatureEnabled } from 'ducks/organizations'

import EmptyState from 'components/Shared/EmptyState'
import { IconButton } from 'components/Shared/Icon'
import { GroupedAccordion } from 'components/Shared/Accordion'

import CreateObjectOptions from './CreateObject'
import UpdateObjectOptions from './UpdateObject'
import DeleteObjectOptions from './DeleteObject'
import NavigateOptions from './Navigate'
import AuthenticateOptions from './Authenticate'
import SignupOptions from './Signup'
import CreateAssociation from './CreateAssociation'
import ShareOptions from './Share'
import SetInputOptions from './SetInput'
import APICallOptions from './APICall'
import APIAuthOptions from './APIAuth'
import NotificationOptions from './Notification'
import ExternalLink from './ExternalLink'
import AdvancedOptions from './AdvancedOptions'
import CustomAction from './CustomAction'
import DisabledActionItem from './DisabledActionItem'

import { THEMES } from '../../../../constants'

class ActionItem extends Component {
  renderOptionsSub = () => {
    const {
      appId,
      componentId,
      objectId,
      action,
      field,
      reference,
      formName,
      omitContextualTables,
    } = this.props

    const childProps = {
      field,
      appId,
      componentId,
      reference,
      objectId,
      formName,
      actionId: action.id,
      options: action.options,
      menuTheme: THEMES.DATA,
      omitContextualTables,
    }

    switch (action.actionType) {
      case actionTypes.CREATE_OBJECT:
        return <CreateObjectOptions {...childProps} />

      case actionTypes.UPDATE_OBJECT:
        return <UpdateObjectOptions {...childProps} />

      case actionTypes.DELETE_OBJECT:
        return <DeleteObjectOptions {...childProps} />

      case actionTypes.CREATE_ASSOCIATION:
        return <CreateAssociation {...childProps} />

      case actionTypes.DELETE_ASSOCIATION:
        return <CreateAssociation {...childProps} />

      case actionTypes.NAVIGATE:
        return <NavigateOptions {...childProps} />

      case actionTypes.EXTERNAL_LINK:
        return <ExternalLink {...childProps} />

      case actionTypes.AUTHENTICATE:
        return <AuthenticateOptions {...childProps} />

      case actionTypes.SIGNUP:
        return <SignupOptions {...childProps} />

      case actionTypes.SHARE:
        return <ShareOptions {...childProps} />

      case actionTypes.SET_INPUT_VALUE:
        return <SetInputOptions {...childProps} />

      case actionTypes.PUSH_NOTIFICATION:
        return <NotificationOptions {...childProps} />

      case actionTypes.CALL_API:
        return <APICallOptions {...childProps} />

      case actionTypes.AUTHENTICATE_API:
        return <APIAuthOptions {...childProps} type="login" />

      case actionTypes.SIGNUP_API:
        return <APIAuthOptions {...childProps} type="signup" />

      case actionTypes.CUSTOM:
        return <CustomAction {...childProps} />

      case actionTypes.AUTHENTICATE_EXTERNAL:
        return <CustomAction {...childProps} />

      case actionTypes.SIGNUP_EXTERNAL:
        return <CustomAction {...childProps} />

      default:
        return <EmptyState>No Options</EmptyState>
    }
  }

  renderOptions = () => {
    return (
      <div className="action-item-form-item-children" ref={this.childrenRef}>
        {this.renderOptionsSub()}
      </div>
    )
  }

  renderAdvanced = () => {
    const { action, field, formName, objectId, reference } = this.props

    return (
      <>
        <AdvancedOptions
          action={action}
          field={field}
          formName={formName}
          objectId={objectId}
          actionId={action.id}
          reference={reference}
        />
      </>
    )
  }

  updateCustomAction = async () => {
    const { showModal, customAction } = this.props

    try {
      await showModal(CUSTOM_ACTION_MODAL, {
        ...customAction.body,
        id: customAction.id,
      })
    } catch (err) {
      console.error(`Custom action not saved: ${err}`)
    }
  }

  getCustomActionSubtitle = customAction => {
    const type = customAction.body.type

    const subtitle = `${
      type.charAt(0).toUpperCase() + type.slice(1)
    } - Custom Action`

    return subtitle
  }

  render() {
    let {
      action,
      onRemove,
      subtitle,
      customAction,
      customActionsDisabled,
      geolocationDisabled,
      handleTrial,
    } = this.props

    const isCustomAction =
      action?.actionType === actionTypes.CUSTOM ||
      action?.actionType === actionTypes.AUTHENTICATE_EXTERNAL ||
      action?.actionType === actionTypes.SIGNUP_EXTERNAL

    // Can't be NULL because it's wrapped in SortableElement
    if (!action || (isCustomAction && _isEmpty(customAction))) {
      return <span />
    }

    const title = isCustomAction ? customAction.body.name : actionName(action)

    subtitle = isCustomAction
      ? this.getCustomActionSubtitle(customAction)
      : subtitle

    const icon = action?.options?.conditional ? 'conditional-action' : 'action'

    const isNotificationPermission =
      action?.actionType === actionTypes.LOCATION_PERMISSION

    const isGeolocationAndDisabled =
      geolocationDisabled && isNotificationPermission

    if ((customActionsDisabled && isCustomAction) || isGeolocationAndDisabled) {
      subtitle = isGeolocationAndDisabled ? '' : subtitle

      return (
        <DisabledActionItem
          title={title}
          subtitle={subtitle}
          icon={icon}
          handleTrial={handleTrial}
          onRemove={onRemove}
        />
      )
    }

    return (
      <GroupedAccordion
        boxed
        sortableHandle
        itemId={action.id}
        group="action-item"
        className={'action-item-form-item'}
        color="yellow"
        icon={icon}
        title={
          <div className="action-item-form-item-header">
            <div className="action-item-type">
              <div className="action-item-type-title">{title}</div>
              <div className="action-item-type-subtitle">{subtitle}</div>
            </div>
            <IconButton type="trash-small" onClick={onRemove} />
          </div>
        }
        renderChildren={this.renderOptions}
        isEditable={isCustomAction && !customAction.deleted}
        editAction={this.updateCustomAction}
        renderAdvanced={this.renderAdvanced}
      />
    )
  }
}

const mapStateToProps = (state, { appId, action }) => {
  const isActionEnabled = isFeatureEnabled(state, 'customIntegrations')
  const isGeolocationEnabled = isFeatureEnabled(state, 'geolocation')

  return {
    customActionsDisabled: !isActionEnabled,
    geolocationDisabled: !isGeolocationEnabled,
    datasourceType: getDatasourceType(state, appId),
    subtitle: actionSubtitle(state, appId, action),
    customAction: getCustomAction(
      state,
      appId,
      action?.options?.customActionId
    ),
  }
}

export default connect(mapStateToProps, { showModal })(
  SortableElement(ActionItem)
)
